import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Use named import

function Navbar({ token, setToken }) {
  const navigate = useNavigate();

  const [user, setUser] = useState(null);

  useEffect(() => {
    if (token) {
      const decodedUser = jwtDecode(token);
      setUser(decodedUser);
    }
  }, [token]);

  const handleLogout = () => {
    setToken(null); // Clear the token
    navigate('/login'); // Redirect to login
  };

  if (!token) {
    // If no token, don't show the navbar
    return null;
  }

  return (
    <nav style={{ display: 'flex', gap: '10px', padding: '10px', background: '#eee' }}>
      <Link to="/">Builder</Link>
      {user && ["admin", "administrator"].includes(user.role.toLowerCase()) && <Link to="/company-management">Company</Link>}
      {user && ["admin", "administrator"].includes(user.role.toLowerCase()) && <Link to="/package-selector">Package Selector</Link>}
      <Link to="/logs">Logs</Link>
      <button onClick={handleLogout}>Logout</button>
    </nav>
  );
}

export default Navbar;