import React, { useState, useEffect } from 'react';

const OSSelector = () => {
    const [osList, setOsList] = useState([]);
    const [selectedOs, setSelectedOs] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [language, setLanguage] = useState('');
    const [version, setVersion] = useState('');

    useEffect(() => {
        // Fetch the list of operating systems from the backend
        fetch('http://localhost:8000/api/os-list')
            .then(response => response.json())
            .then(data => setOsList(data))
            .catch(error => console.error('Error fetching OS list:', error));
    }, []);

    const handleSelect = (os) => {
        setSelectedOs(os);
        setDialogOpen(true);
    };

    const handleSave = () => {
        const data = {
            updateId: selectedOs.updateId,
            language,
            version
        };

        fetch('http://localhost:8000/api/save-selection', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then(response => response.json())
        .then(result => {
            console.log('Success:', result);
            setDialogOpen(false);
        })
        .catch(error => console.error('Error saving selection:', error));
    };

    return (
        <div>
            <h1>OS Selector</h1>
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Version</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {osList.map(os => (
                        <tr key={os.updateId}>
                            <td>{os.name}</td>
                            <td>{os.version}</td>
                            <td><button onClick={() => handleSelect(os)}>Select</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {dialogOpen && (
                <div className="dialog">
                    <h2>Select Options</h2>
                    <label>
                        Language:
                        <input type="text" value={language} onChange={(e) => setLanguage(e.target.value)} />
                    </label>
                    <label>
                        Version:
                        <select value={version} onChange={(e) => setVersion(e.target.value)}>
                            <option value="home">Home</option>
                            <option value="pro">Pro</option>
                        </select>
                    </label>
                    <button onClick={() => setDialogOpen(false)}>Cancel</button>
                    <button onClick={handleSave}>Save</button>
                </div>
            )}
        </div>
    );
};

export default OSSelector;