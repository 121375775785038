import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // For decoding session token


import Login from './Login';
import Navbar from './Navbar'; // Import the Navbar
import UniversalAdminNavbar from './UniversalAdminNavbar';
import ProtectedRoute from './ProtectedRoute';
import MainContent from './MainContent';
import CompanyManagement from './CompanyManagement';
import PackageSelector from './PackageSelector';
import UniversalAdminCompanyManagement from './UniversalAdminCompanyManagement';
import UniversalAdminPackageSelector from './UniversalAdminPackageSelector';
import UniversalAdminLogs from './UniversalAdminLogs';
import Logs from './Logs';



import OSSelector from './OSSelector';





//import Header from './Header';
//import Footer from './Footer';

function App() {
  const [token, setToken] = useState(() => localStorage.getItem('token') || null);
//  const [isTokenValid, setIsTokenValid] = React.useState(true);

  const [user, setUser] = useState(null);


  // Save token to localStorage whenever it changes
  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
      const decodedUser = jwtDecode(token);
      setUser(decodedUser);
      const decodedCompany = jwtDecode(token);
      setUser(decodedCompany);
    } else {
      localStorage.removeItem('token'); // Clean up on logout
    }
  }, [token]);

  return (
    <Router>
      {/* Optionally include Header */}
      {/* <Header /> */}
            {/* Conditionally render the Navbar if token is present */}
            <Navbar token={token} setToken={setToken}/>
            {/* Conditionally render the Universal Admin Navbar if user belongs to universal admin company */}
            {user && user.company_id === 1 && <UniversalAdminNavbar token={token} setToken={setToken}/>}

      <Routes>
        {/* Route for Login */}
        <Route path="/Login" element={<Login setToken={setToken} />} />

        {/* Protected Route for Main Content */}
        <Route
          path="/*"
          element={
            <ProtectedRoute
              component={MainContent}
              token={token}
              setToken={setToken}
            />
          }
        />
        <Route
          path="/company-management"
          element={
            <ProtectedRoute
              component={CompanyManagement}
              token={token}
              setToken={setToken}
              adminOnly={true} // handled in ProtectedRoute.js
            />
          }
        />
                <Route
          path="/package-selector"
          element={
            <ProtectedRoute
              component={PackageSelector}
              token={token}
              setToken={setToken}
              adminOnly={true} // handled in ProtectedRoute.js
            />
          }
        />
        <Route
          path="/os-selector"
          element={
            <ProtectedRoute
              component={OSSelector}
              token={token}
              setToken={setToken}
              adminOnly={true} // handled in ProtectedRoute.js
            />
          }
        />
        <Route
          path="/logs"
          element={
            <ProtectedRoute
              component={Logs}
              token={token}
              setToken={setToken}
              adminOnly={true} // handled in ProtectedRoute.js
            />
          }
        />
        <Route
          path="/universal-admin-company-management"
          element={
            <ProtectedRoute
              component={UniversalAdminCompanyManagement}
              token={token}
              setToken={setToken}
              adminOnly={true} // handled in ProtectedRoute.js
              universalAdminOnly={true} // handled in ProtectedRoute.js
            />
          }
        />
        <Route
        path="/universal-admin-logs"
        element={
          <ProtectedRoute
            component={UniversalAdminLogs}
            token={token}
            setToken={setToken}
            adminOnly={true} // handled in ProtectedRoute.js
            universalAdminOnly={true} // handled in ProtectedRoute.js
          />
        }
      />
      <Route
        path="/universal-admin-package-selector"
        element={
          <ProtectedRoute
            component={UniversalAdminPackageSelector}
            token={token}
            setToken={setToken}
            adminOnly={true} // handled in ProtectedRoute.js
            universalAdminOnly={true} // handled in ProtectedRoute.js
          />
        }
      />
      </Routes>

      {/* Optionally include Footer */}
      {/* <Footer /> */}
    </Router>
  );
}

export default App;
