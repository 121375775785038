import React from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

import logo from './logo.svg';
import './App.css';
import { backendUrl } from './Endpoints.js';

import { sanitizeInput, isValidEmail } from './utils.js'; // Import function to sanatize string inputs in the frontend


function Login({ setToken }) {
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [sanitizationMessage, setSanitizationMessage] = React.useState('');


  // Handles username input sanitization
  const handleUsernameChange = (e) => {
    const inputVal = e.target.value;
    const { sanitized, unsanatizedFound } = sanitizeInput(inputVal);
    setUsername(sanitized);
    setSanitizationMessage(unsanatizedFound ? 'You may only use alphanumeric and special characters' : '');
  };

  // Handles password input sanitization
  const handlePasswordChange = (e) => {
    const inputVal = e.target.value;
    const { sanitized, unsanatizedFound } = sanitizeInput(inputVal);
    setPassword(sanitized);
    setSanitizationMessage(unsanatizedFound ? 'You may only use alphanumeric and special characters' : '');
  };


  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    
    // Ensures that email address is valid
    if (!isValidEmail(username)) {
      alert('Please provide a valid email address.');
      return;
    }

    try {
      const response = await fetch(`${backendUrl}api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          'username': username.toLowerCase(),
          'password': password,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setToken(data.access_token); // Store the token
        
        // Decode the token to get company_id
        const decodedToken = jwtDecode(data.access_token);
        const companyId = decodedToken.company_id;
        
        // Store companyId in localStorage or state if needed
        localStorage.setItem('companyId', companyId);

        navigate('/'); // Redirect to main content page
      } else {
        const errorData = await response.json();
        alert(`Login failed: ${errorData.detail || 'Unknown error'}`);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>QXBuilder</h1>
          <img src={logo} className="App-logo" alt="logo" />
        <form onSubmit={handleSubmit}>
          <h2>Login</h2>
          <label>
            Username:
            <input
              type="text"
              value={username}
              onChange={handleUsernameChange}
              required
            />
          </label>
          <br />
          <label>
            Password:
            <input
              type="password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
          </label>
          {sanitizationMessage && <div style={{ color: 'red' }}>{sanitizationMessage}</div>}
          <br />
          <button type="submit">Login</button>
        </form>
      </header>
    </div>
  );
}

export default Login;
