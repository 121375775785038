import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // For decoding session token

function UniversalAdminNavbar({ token, setToken }) {

  const [user, setUser] = useState(null);

  useEffect(() => {
    if (token) {
      const decodedUser = jwtDecode(token);
      setUser(decodedUser);
    }
  }, [token]);

  if (!token) {
    // If no token, don't show the navbar
    return null;
  }

  return (
    <nav style={{ display: 'flex', gap: '10px', padding: '10px', background: '#eee' }}>
      <>Universal Admin pages</>
      {user && ["admin", "administrator"].includes(user.role.toLowerCase()) && <Link to="/universal-admin-company-management">Company</Link>}
      {user && ["admin", "administrator"].includes(user.role.toLowerCase()) && <Link to="/universal-admin-package-selector">Package Selector</Link>}
      {user && ["admin", "administrator"].includes(user.role.toLowerCase()) && <Link to="/universal-admin-logs">Logs</Link>}
    </nav>
  );
}

export default UniversalAdminNavbar;