import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import logo from './logo.svg';
import './App.css';
import { backendUrl } from './Endpoints.js';

function MainContent({ token, setToken }) {
  // Your main page content goes here
  
//  const [state, setState] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState('');
//  const [executables, setExecutables] = useState([]); // To allow multiple executables per department
//  const [selectedExecutable, setSelectedExecutable] = useState(''); // To allow multiple executables per department
  const navigate = useNavigate();

  const handleLogout = () => {
    setToken(null); // Clear the token to log out
  };

  useEffect(() => {
    // Fetch departments
    const fetchDepartments = async () => {
      try {
        const response = await fetch(`${backendUrl}departments/`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setDepartments(data);
        } else {
          // Handle errors (e.g., token expired)
          setToken(null);
          navigate('/login');
        }
      } catch (error) {
        console.error('Error fetching departments:', error);
      }
    };

    fetchDepartments();
  }, [token, setToken, navigate]);

  // Handle department selection
  const handleDepartmentChange = async (e) => {
    const departmentId = e.target.value;
    setSelectedDepartment(departmentId);

/*    // To allow multiple executables per department
      // Fetch executables for the selected department
    try {
      const response = await fetch(`${backendUrl}executables/${departmentId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setExecutables(data);
      } else {
        // Handle errors
        alert('Failed to fetch executables');
      }
    } catch (error) {
      console.error('Error fetching executables:', error);
    }
      */
  };

  /*
  // To allow multiple executables per department
  // Handle executable selection
  const handleExecutableChange = (e) => {
    setSelectedExecutable(e.target.value);
  };
*/

  // Handle file download
  const handleDownload = async () => {
    try {
//      const response = await fetch(`${backendUrl}download-executable/${selectedExecutable}`, {  // To allow multiple executables per department
        const response = await fetch(`${backendUrl}download-executable/${selectedDepartment}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        // Get the download link from the response
        const data = await response.json();
        const downloadLink = `${backendUrl}${data.file_url}`;

        // Use the download link to download the file
        const link = document.createElement('a');
        link.href = downloadLink;
        link.setAttribute('download', 'QX_Builder.ps1'); // Adjust filename if needed
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        const errorData = await response.json();
        console.error('Download failed:', response.status, errorData);
        alert('Download failed');
      }
    } catch (error) {
      console.error('Error downloading executable:', error);
    }
  };

  return (
    <div>
      <header className="App-header">
        <h1>QXBuilder</h1>
        <img src={logo} className="App-logo" alt="logo" />
        <button onClick={handleLogout}>Logout</button>
              {/* Department Dropdown */}
      <label>
        Select Department:
        <select value={selectedDepartment} onChange={handleDepartmentChange}>
          <option value="">--Select Department--</option>
          {departments.map((dept) => (
            <option key={dept.department_id} value={dept.department_id}>
              {dept.name}
            </option>
          ))}
        </select>
      </label>

 {/* To allow multiple executables per department
      {executables.length > 0 && (
        <div>
          <label>
            Select Executable:
            <select value={selectedExecutable} onChange={handleExecutableChange}>
              <option value="">--Select Executable--</option>
              {executables.map((exe) => (
                <option key={exe.executable_id} value={exe.executable_id}>
                  {exe.executable_id}
                </option>
              ))}
            </select>
          </label>
        </div>
      )}

      {selectedExecutable && (
        <button onClick={handleDownload}>Download File</button>
      )}
*/}

      {/* Download Button */}
      {selectedDepartment && (
        <button onClick={handleDownload}>Download File</button>
      )}


      </header>
    </div>
    
  );
}

export default MainContent;
