import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom'; // Handles redirecting if token is invalid

import { backendUrl } from './Endpoints.js';    // Imports the backend URL

import { sanitizeInput, isValidEmail } from './utils.js'; // Import function to sanatize string inputs in the frontend


// import logo from './logo.svg';
import './App.css';



  
// Dialog styling
const dialogStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: '1px solid #000',
    padding: '20px',
    background: '#fff',
    zIndex: 1000,
  };

  // Table header styling
  const tableHeaderStyle = {
    textDecoration: 'underline',
    cursor: 'pointer',
    padding: '5px',
    borderBottom: '1px solid #ccc'
  };
  

// Table cell styling
  const tableCellStyle = {
    padding: '5px',
    borderBottom: '1px solid #eee'
  };





  function UniversalAdminCompanyManagement({ token, setToken }) {
    const navigate = useNavigate();

    // Handles toggling advanced column visibility
    const [advancedVisibility, setAdvancedVisibility] = useState({
        allColumns: false,
    });
        
    const toggleAdvancedVisibility = (column) => {
        setAdvancedVisibility(prevState => ({
            ...prevState,
            [column]: !prevState[column],
      }));
    };


        // Modify companies
    const [companies, setCompanies] = useState([]);
    const [filteredCompany, setFilteredCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [companySearchTerm, setCompanySearchTerm] = useState('');
    const [showAddCompanyDialog, setShowAddCompanyDialog] = useState(false);
    const [showEditCompanyDialog, setShowEditCompanyDialog] = useState(false);
    const [showDeleteCompanyDialog, setShowDeleteCompanyDialog] = useState(false);
    const [CompanyName, setCompanyName] = useState('');
    const [companyNameWarning, setCompanyNameWarning] = useState('');
    const [Subdomain, setSubdomain] = useState('');
    const [subdomainWarning, setSubdomainWarning] = useState('');
    const [BrandingInfo, setBrandingInfo] = useState('');
    const [brandingInfoWarning, setBrandingInfoWarning] = useState('');
    const [activeSubscription, setActiveSubscription] = useState(true);

  
  useEffect(() => {
    // Fetch companies from the backend
    const fetchCompanies = async () => {
      try {
        const response = await fetch(`${backendUrl}universal/companies`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setCompanies(data);
          setFilteredCompanies(data);
        } else {
          // Token might be invalid or not admin
          setToken(null);
          navigate('/login');
        }
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    };
  
    fetchCompanies();
  }, [token, setToken, navigate]);
  
  // Filter companies as companySearchTerm changes
  useEffect(() => {
    const term = companySearchTerm.toLowerCase();
    const { sanitized } = sanitizeInput(term);
    const filteredCompany = companies.filter(c => c.name.toLowerCase().includes(sanitized));
    setFilteredCompanies(filteredCompany);
  }, [companySearchTerm, companies]);
  
  const handleCompanySearchChange = (e) => {
    setCompanySearchTerm(e.target.value);
  };
  
  const handleCompanyClick = (company) => {
    if (selectedCompany && selectedCompany.company_id === company.company_id) {
      // Deselect
      setSelectedCompany(null);
    } else {
      // Select this company
      setSelectedCompany(company);
    }
    setSelectedUser(null);
    setSelectedDepartment(null);
    setSelectedConfiguration(null);
    setSelectedConfigurationChildTables(null);
  };
  
  const handleAddCompanyClick = () => {
    const { sanitized, /*unsanatizedFound*/ } = sanitizeInput(companySearchTerm); // Inputs search term into name field
    if (sanitized.trim() !== '') {
      setCompanyName(sanitized);
    } else {
      setCompanyName('');
    }

    // Resets field values
    setCompanyNameWarning('');
    setSubdomain('');
    setBrandingInfo('');
    setActiveSubscription(true);

    setShowAddCompanyDialog(true);
  };
  
  const handleAddCompany = async () => {
    if (!CompanyName || !Subdomain || !BrandingInfo) {
      alert('Please fill in all company fields');
      return;
    }
  
    try {
      const response = await fetch(`${backendUrl}universal/add-company`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            name: CompanyName,
            subdomain: Subdomain,
            branding_info: BrandingInfo,
            active_subscription: activeSubscription
          }),
        });
  
        if (response.ok) {
            const Company = await response.json();
            const updatedCompanies = [...companies, Company];
            setCompanies(updatedCompanies);
            const { sanitized } = sanitizeInput(companySearchTerm.toLowerCase());
            setFilteredCompanies(updatedCompanies.filter(c => c.name.toLowerCase().includes(sanitized)));
            alert(CompanyName + ' successfully added');
            setShowAddCompanyDialog(false);
            setCompanyName('');
            setSelectedCompany(Company);
          } else {
            const errData = await response.json();
            alert(`Failed to add company: ${errData.detail || 'Unknown error'}`);
          }
        } catch (error) {
          console.error('Error adding company:', error);
        }
      };
  
      // >>> Edit Company <<<
    const handleEditCompanyClick = () => {
      if (!selectedCompany) {
        alert('No company selected to edit');
        return;
      }
      // Pre-fill with current company info
      setCompanyName(selectedCompany.name);
      setSubdomain(selectedCompany.subdomain);
      setBrandingInfo(selectedCompany.branding_info);
      setActiveSubscription(selectedCompany.active_subscription);
      setShowEditCompanyDialog(true);
    };
  
    const handleEditCompany = async () => {
      if (!selectedCompany) return;
      // sanitize again
      const { sanitized: companyNameSanitized, unsanatizedFound: companyNameInvalid } = sanitizeInput(CompanyName);
      const { sanitized: SubdomainSanitized, unsanatizedFound: subdomainInvalid } = sanitizeInput(Subdomain);
      const { sanitized: BrandingInfoSanitized, unsanatizedFound: brandingInfoInvalid } = sanitizeInput(BrandingInfo);
  
      if (companyNameInvalid) {
        setCompanyNameWarning("You may only use alphanumeric and special characters");
        return;
      }
      if (subdomainInvalid) {
        setCompanyNameWarning("You may only use alphanumeric and special characters");
        return;
      }
      if (brandingInfoInvalid) {
        setCompanyNameWarning("You may only use alphanumeric and special characters");
        return;
      }
  
      try {
        const response = await fetch(`${backendUrl}universal/update-company?company_id=${selectedCompany.company_id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({
            name: companyNameSanitized,
            subdomain: SubdomainSanitized,
            branding_info: BrandingInfoSanitized,
            active_subscription: activeSubscription
          }),
        });
  
        if (response.ok) {
          const updatedCompany = await response.json();
          // Update local company array
          let updatedCompanies = companies.filter(c => c.company_id !== selectedCompany.company_id);
          updatedCompanies = [...updatedCompanies, updatedCompany];
          setCompanies(updatedCompanies);
          const { sanitized } = sanitizeInput(companySearchTerm.toLowerCase());
          setFilteredCompanies(updatedCompanies.filter(d => d.name.toLowerCase().includes(sanitized)));
          alert(companyNameSanitized + ' successfully updated');
          setShowEditCompanyDialog(false);
          setCompanyName('');
          setSelectedCompany(updatedCompany);
        } else {
          const errData = await response.json();
          alert(`Failed to edit company: ${errData.detail || 'Unknown error'}`);
        }
      } catch (error) {
        console.error('Error editing company:', error);
      }
    };
  
  const handleDeleteCompanyClick = () => {
    if (!selectedCompany) {
      alert('No company selected to delete');
      return;
    }
    setShowDeleteCompanyDialog(true);
  };
  
  const handleDeleteCompany = async () => {
    if (!selectedCompany) return;
    console.log(selectedCompany);
    try {
      const response = await fetch(`${backendUrl}universal/delete-company?company_id=${selectedCompany.company_id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const updatedCompanies = companies.filter(c => c.company_id !== selectedCompany.company_id);
        setCompanies(updatedCompanies);
        const { sanitized } = sanitizeInput(companySearchTerm.toLowerCase());
        setFilteredCompanies(updatedCompanies.filter(c => c.name.toLowerCase().includes(sanitized)));
        alert(selectedCompany.name + ' deleted successfully');
        setSelectedCompany(null);
        setShowDeleteCompanyDialog(false);
      } else {
        const errData = await response.json();
          alert(`Failed to delete company: ${errData.detail || 'Unknown error'}`);
      }
    } catch (error) {
      console.error('Error deleting company:', error);
    }
  };
  
    // On-change handlers for the dialog inputs with inline sanitization
    const handleCompanyNameChange = (c) => {
        const { sanitized, unsanatizedFound } = sanitizeInput(c.target.value);
        setCompanyName(sanitized);
        setCompanyNameWarning(unsanatizedFound ? "You may only use alphanumeric and the following special characters: .,-@!#$%&*()" : "");
      };

    const handleSubdomainChange = (c) => {
      const { sanitized, unsanatizedFound } = sanitizeInput(c.target.value);
      setSubdomain(sanitized);
      setSubdomainWarning(unsanatizedFound ? "You may only use alphanumeric and the following special characters: .,-@!#$%&*()" : "");
    };

    const handleBrandingInfoChange = (c) => {
      const { sanitized, unsanatizedFound } = sanitizeInput(c.target.value);
      setBrandingInfo(sanitized);
      setBrandingInfoWarning(unsanatizedFound ? "You may only use alphanumeric and the following special characters: .,-@!#$%&*()" : "");
    };
    
      


    // Modify users
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null); // Tracks which user is selected in the Users table
    const [userSearchTerm, setUserSearchTerm] = useState('');   // Tracks what is enterred in the Users search bar
    const [userSortField, setUserSortField] = useState(null);  // Sorts Users table by 'email' or 'role'
    const [userSortOrder, setUserSortOrder] = useState('asc'); // Sorts Users table by 'asc' or 'desc'
    const [showAddUserDialog, setShowAddUserDialog] = useState(false);
    const [showEditUserDialog, setShowEditUserDialog] = useState(false);
    const [showDeleteUserDialog, setshowDeleteUserDialog] = useState(false);
    const [Email, setEmail] = useState('');
    const [showPasswordField, setShowPasswordField] = useState(false);
    const [Password, setPassword] = useState('');
    const [passwordErrors, setPasswordErrors] = useState([]);   // Used for validating password strength
    const [Role, setRole] = useState('');
    const [emailWarning, setEmailWarning] = useState('');   // Warns of invalid input in Username field
    const [passwordWarning, setPasswordWarning] = useState('');   // Warns of invalid input in Password field
    const [roleWarning, setRoleWarning] = useState('');   // Warns of invalid input in Role field
    const [showCannotDeleteSelfDialog, setShowCannotDeleteSelfDialog] = useState(false);    // Informs the user that they cannot delete their own user on attempt



  function validatePasswordStrength(password) {
  /* Passwords must contain at least:
   8 characters
   1 capital letter
   1 lowercase letter
   1 digit
   1 special character: !@#$%^&*
  */

    // Minimum length
    if (password.length < 8) {
      passwordErrors.push("Password must be at least 8 characters long.");
    }
    
    // Must contain at least one capital letter
    if (!/[A-Z]/.test(password)) {
      passwordErrors.push("Password must contain at least one capital letter.");
    }
  
    // Must contain at least one lowercase letter
    if (!/[a-z]/.test(password)) {
      passwordErrors.push("Password must contain at least one lowercase letter.");
    }
  
    // Must contain at least one digit
    if (!/\d/.test(password)) {
      passwordErrors.push("Password must contain at least one digit.");
    }
  
    // Must contain at least one special character
    if (!/[!@#$%^&*]/.test(password)) {
      passwordErrors.push("Password must contain at least one special character.");
    }
  
    // Return array of error messages
    return passwordErrors;
  }


  useEffect(() => {
    // Fetch users from the backend
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${backendUrl}universal/users?company_id=${selectedCompany.company_id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setUsers(data);
          setFilteredUsers(data);
        } else {
          // Token might be invalid or not admin
          setToken(null);
          navigate('/login');
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    if (selectedCompany) {
      fetchUsers();
    }
  }, [selectedCompany, token, setToken, navigate]);

  // Filter users as userSearchTerm changes
  // >>> Filter & Sort Users <<<
  useEffect(() => {
    let tempUsers = [...users];

    // Filter
    const { sanitized } = sanitizeInput(userSearchTerm.toLowerCase());
    tempUsers = tempUsers.filter(u => u.email.toLowerCase().includes(sanitized) || (u.role || '').toLowerCase().includes(sanitized));

    // Sort
    if (userSortField) {
      tempUsers.sort((a, b) => {
        let valA = userSortField === 'role' ? (a.role || '') : a.email;
        let valB = userSortField === 'role' ? (b.role || '') : b.email;

        valA = valA.toLowerCase();
        valB = valB.toLowerCase();

        if (valA < valB) return userSortOrder === 'asc' ? -1 : 1;
        if (valA > valB) return userSortOrder === 'asc' ? 1 : -1;
        return 0;
      });
    }

    setFilteredUsers(tempUsers);
  }, [users, userSearchTerm, userSortField, userSortOrder]);

  // >>> Table Column Click Handlers for Sorting <<<
  const handleSort = (field) => {
    if (userSortField === field) {
      // Toggle sort order
      setUserSortOrder(userSortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setUserSortField(field);
      setUserSortOrder('asc');
    }
  };

    // >>> Searching Users <<<
  const handleUserSearchChange = (e) => {
    setUserSearchTerm(e.target.value);
  };

    // >>> Selecting a User in the Table <<<
  const handleUserRowClick = (user) => {
    if (selectedUser && selectedUser.user_id === user.user_id) {
        setSelectedUser(null);  // Deselect if already selected
      } else {
        setSelectedUser(user);  // Select this configuration
      }  };



    // >>> Add User <<<
  const handleAddUserClick = () => {
    // if there is text in the search bar, it is automatically entered into the Email field
    const { sanitized, /*unsanatizedFound*/ } = sanitizeInput(userSearchTerm);
    if (sanitized.trim() !== '') {
      setEmail(sanitized);
    } else {
      setEmail('');
    }
    setPassword('');
    setPasswordErrors([]);
    setRole('');
    setEmailWarning('');
    setPasswordWarning('');
    setRoleWarning('');
    setShowAddUserDialog(true);
  };

  // Ensures that all fields are filled in
  const handleAddUser = async () => {
    if (!Email || !Password || !Role) {
      alert('Please fill in all fields');
      return;
    }

    // Ensures that email address is valid
    if (!isValidEmail(Email)) {
        alert('Please provide a valid email address.');
        return;
      }

    // Validates password strength 
    const passwordErrors = validatePasswordStrength(Password);
    setPasswordErrors(passwordErrors);
    if (passwordErrors.length > 0) {
        alert('Please fix password errors before submitting.');
        return;
    }

    // Passes the  user information to the backend
    try {
      const response = await fetch(`${backendUrl}universal/add-user?company_id=${selectedCompany.company_id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            email: Email.toLowerCase(),
            password: Password,
            role: Role,
          }),
        });

        if (response.ok) {
            const User = await response.json();
            const updatedUsers = [...users, User];
            setUsers(updatedUsers);
            const { sanitized } = sanitizeInput(userSearchTerm.toLowerCase());
            setFilteredUsers(updatedUsers.filter(u => u.email.toLowerCase().includes(sanitized)));
            alert(Email + ' successfully added');
            setShowAddUserDialog(false);
            setEmail('');
            setPassword('');
            setPasswordErrors([]);
            setRole('');
          } else {
            const errData = await response.json();
            alert(`Failed to add user: ${errData.detail || 'Unknown error'}`);
          }
        } catch (error) {
          console.error('Error adding user:', error);
        }
      };


    // >>> Edit User <<<
  const handleEditUserClick = () => {
    if (!selectedUser) {
      alert('No user selected to edit');
      return;
    }
    // Pre-fill with current user info
    setEmail(selectedUser.email);
    setShowPasswordField(false); // hide password field by default
    setPassword(''); // we won't show the hashed password
    setPasswordErrors([]);
    setRole(selectedUser.role);
    setEmailWarning('');
    setShowEditUserDialog(true);
  };

  const handleEditUser = async () => {
    if (!selectedUser) return;
    // sanitize again
    const { sanitized: emailSanitized, unsanatizedFound: emailInvalid } = sanitizeInput(Email);
    const { sanitized: pwSanitized, unsanatizedFound: pwInvalid } = sanitizeInput(Password);
    const { sanitized: roleSanitized, unsanatizedFound: roleInvalid } = sanitizeInput(Role);

    if (emailInvalid || pwInvalid || roleInvalid) {
      setEmailWarning("You may only use alphanumeric and special characters");
      return;
    }

    // Ensures that email address is valid
    if (!isValidEmail(Email)) {
        alert('Please provide a valid email address.');
        return;
      }

    // If password is not blank, validates password strength 
    if (pwSanitized){    
        const passwordErrors = validatePasswordStrength(Password);
        setPasswordErrors(passwordErrors);
        if (passwordErrors.length > 0) {
            alert('Please fix password errors before submitting.');
            return;
        }
    }

    try {
      const response = await fetch(`${backendUrl}company/update-user?user_id=${selectedUser.user_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          email: emailSanitized.toLowerCase(),
          password: pwSanitized, // can be empty string if not changing password
          role: roleSanitized,
        }),
      });

      if (response.ok) {
        const updatedUser = await response.json();
        // Update local user array
        const updatedUsers = users.map(u => u.user_id === updatedUser.user_id ? updatedUser : u);
        setUsers(updatedUsers);
        alert(emailSanitized + ' successfully updated');
        setShowEditUserDialog(false);
        setEmail('');
        setPassword('');
        setPasswordErrors([]);
        setRole('');
        setSelectedUser(updatedUser);
      } else {
        const errData = await response.json();
        alert(`Failed to edit user: ${errData.detail || 'Unknown error'}`);
      }
    } catch (error) {
      console.error('Error editing user:', error);
    }
  };
  
    // >>> Delete User <<<
  const handleDeleteUserClick = () => {
    if (!selectedUser) {
      alert('No user selected to delete');
      return;
    }
    setshowDeleteUserDialog(true);
  };

  const handleDeleteUser = async () => {
    if (!selectedUser) return;
    try {
      const response = await fetch(`${backendUrl}company/delete-user?user_id=${selectedUser.user_id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const updatedUsers = users.filter(u => u.user_id !== selectedUser.user_id);
        setUsers(updatedUsers);
        const { sanitized } = sanitizeInput(userSearchTerm.toLowerCase());
        setFilteredUsers(updatedUsers.filter(u => u.email.toLowerCase().includes(sanitized)));
        alert(selectedUser.email + ' successfully deleted');
        setSelectedUser(null);
        setshowDeleteUserDialog(false);
      } else {
        const errData = await response.json();
        if (errData.detail === "Cannot delete self") {
          setshowDeleteUserDialog(false);
          setShowCannotDeleteSelfDialog(true);
        } else {
          alert(`Failed to delete user: ${errData.detail || 'Unknown error'}`);
        }
      }
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

    // On-change handlers for the dialog inputs with inline sanitization
    const handleEmailChange = (e) => {
        const { sanitized, unsanatizedFound } = sanitizeInput(e.target.value);
        setEmail(sanitized);
        setEmailWarning(unsanatizedFound ? "You may only use alphanumeric and the following special characters: .,-@!#$%&*()" : "");
      };
    
      const handlePasswordChange = (e) => {
        const { sanitized, unsanatizedFound } = sanitizeInput(e.target.value);
        setPassword(sanitized);
        setPasswordWarning(unsanatizedFound ? "You may only use alphanumeric and the following special characters: .,-@!#$%&*()" : "");
      };
    
      const handleRoleChange = (e) => {
        const { sanitized, unsanatizedFound } = sanitizeInput(e.target.value);
        setRole(sanitized);
        setRoleWarning(unsanatizedFound ? "You may only use alphanumeric and special characters" : "");
      };

      










      
  // Modify departments
  const [departments, setDepartments] = useState([]);
  const [filteredDepartment, setFilteredDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [departmentSearchTerm, setDepartmentSearchTerm] = useState('');
  const [showAddDepartmentDialog, setShowAddDepartmentDialog] = useState(false);
  const [showEditDepartmentDialog, setShowEditDepartmentDialog] = useState(false);
  const [showDeleteDepartmentDialog, setShowDeleteDepartmentDialog] = useState(false);
  const [DepartmentName, setDepartmentName] = useState('');
  const [departmentNameWarning, setDepartmentNameWarning] = useState('');

useEffect(() => {
  // Fetch departments from the backend
  const fetchDepartments = async () => {
    try {
      const response = await fetch(`${backendUrl}universal/departments?company_id=${selectedCompany.company_id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setDepartments(data);
        setFilteredDepartments(data);
      } else {
        // Token might be invalid or not admin
        setToken(null);
        navigate('/login');
      }
    } catch (error) {
      console.error('Error fetching departments:', error);
    }
  };

  if (selectedCompany) {
    fetchDepartments();
  }
}, [selectedCompany, token, setToken, navigate]);

// Filter departments as departmentSearchTerm changes
useEffect(() => {
  const term = departmentSearchTerm.toLowerCase();
  const { sanitized } = sanitizeInput(term);
  const filteredDepartmentName = departments.filter(d => d.name.toLowerCase().includes(sanitized));
  setFilteredDepartments(filteredDepartmentName);
}, [departmentSearchTerm, departments]);

const handleDepartmentSearchChange = (e) => {
  setDepartmentSearchTerm(e.target.value);
};

const handleDepartmentClick = (department) => {
  if (selectedDepartment && selectedDepartment.department_id === department.department_id) {
    // Deselect
    setSelectedDepartment(null);
  } else {
    // Select this department
    setSelectedDepartment(department);
  }
  setSelectedConfiguration(null);
  setSelectedConfigurationChildTables(null);
};

const handleAddDepartmentClick = () => {
  const { sanitized, /*unsanatizedFound*/ } = sanitizeInput(departmentSearchTerm);
  if (sanitized.trim() !== '') {
    setDepartmentName(sanitized);
  } else {
    setDepartmentName('');
  }
  setDepartmentNameWarning('');
  setShowAddDepartmentDialog(true);
};

const handleAddDepartment = async () => {
  if (!DepartmentName) {
    alert('Please fill in department field');
    return;
  }

  try {
    const response = await fetch(`${backendUrl}universal/add-department?company_id=${selectedCompany.company_id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
          name: DepartmentName
        }),
      });

      if (response.ok) {
          const Department = await response.json();
          const updatedDepartments = [...departments, Department];
          setDepartments(updatedDepartments);
          const { sanitized } = sanitizeInput(departmentSearchTerm.toLowerCase());
          setFilteredDepartments(updatedDepartments.filter(d => d.name.toLowerCase().includes(sanitized)));
          alert(DepartmentName + ' successfully added');
          setShowAddDepartmentDialog(false);
          setDepartmentName('');
          setSelectedDepartment(Department);
        } else {
          const errData = await response.json();
          alert(`Failed to add department: ${errData.detail || 'Unknown error'}`);
        }
      } catch (error) {
        console.error('Error adding department:', error);
      }
    };

    // >>> Edit Department <<<
  const handleEditDepartmentClick = () => {
    if (!selectedDepartment) {
      alert('No department selected to edit');
      return;
    }
    // Pre-fill with current department info
    setDepartmentName(selectedDepartment.name);
    setShowEditDepartmentDialog(true);
  };

  const handleEditDepartment = async () => {
    if (!selectedDepartment) return;
    // sanitize again
    const { sanitized: departmentNameSanitized, unsanatizedFound: departmentNameInvalid } = sanitizeInput(DepartmentName);

    if (departmentNameInvalid) {
      setDepartmentNameWarning("You may only use alphanumeric and special characters");
      return;
    }

    try {
      const response = await fetch(`${backendUrl}company/update-department?department_id=${selectedDepartment.department_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          name: departmentNameSanitized,
        }),
      });

      if (response.ok) {
        const updatedDepartment = await response.json();
        // Update local department array
        let updatedDepartments = departments.filter(d => d.department_id !== selectedDepartment.department_id);
        updatedDepartments = [...updatedDepartments, updatedDepartment];
        setDepartments(updatedDepartments);
        const { sanitized } = sanitizeInput(departmentSearchTerm.toLowerCase());
        setFilteredDepartments(updatedDepartments.filter(d => d.name.toLowerCase().includes(sanitized)));
        alert(departmentNameSanitized + ' successfully updated');
        setShowEditDepartmentDialog(false);
        setDepartmentName('');
        setSelectedDepartment(updatedDepartment);
      } else {
        const errData = await response.json();
        alert(`Failed to edit department: ${errData.detail || 'Unknown error'}`);
      }
    } catch (error) {
      console.error('Error editing department:', error);
    }
  };

const handleDeleteDepartmentClick = () => {
  if (!selectedDepartment) {
    alert('No department selected to delete');
    return;
  }
  setShowDeleteDepartmentDialog(true);
};

const handleDeleteDepartment = async () => {
  if (!selectedDepartment) return;
  try {
    const response = await fetch(`${backendUrl}company/delete-department?department_id=${selectedDepartment.department_id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const updatedDepartments = departments.filter(d => d.department_id !== selectedDepartment.department_id);
      setDepartments(updatedDepartments);
      const { sanitized } = sanitizeInput(departmentSearchTerm.toLowerCase());
      setFilteredDepartments(updatedDepartments.filter(d => d.name.toLowerCase().includes(sanitized)));
      alert(selectedDepartment.name + ' deleted successfully');
      setSelectedDepartment(null);
      setShowDeleteDepartmentDialog(false);
    } else {
      const errData = await response.json();
        alert(`Failed to delete department: ${errData.detail || 'Unknown error'}`);
    }
  } catch (error) {
    console.error('Error deleting department:', error);
  }
};

  // On-change handlers for the dialog inputs with inline sanitization
  const handleDepartmentNameChange = (e) => {
      const { sanitized, unsanatizedFound } = sanitizeInput(e.target.value);
      setDepartmentName(sanitized);
      setDepartmentNameWarning(unsanatizedFound ? "You may only use alphanumeric and the following special characters: .,-@!#$%&*()" : "");
    };
  





  // Modify configurations
  const [configurations, setConfigurations] = useState([]);
  const [selectedConfiguration, setSelectedConfiguration] = useState(null);

/* Code for displaying configuration edit options
  const [filteredConfiguration, setFilteredConfigurations] = useState([]);
  const [configurationSearchTerm, setConfigurationSearchTerm] = useState('');
  const [showAddConfigurationDialog, setShowAddConfigurationDialog] = useState(false);
  const [showEditConfigurationDialog, setShowEditConfigurationDialog] = useState(false);
  const [showDeleteConfigurationDialog, setShowDeleteConfigurationDialog] = useState(false);
  const [Configuration, setConfiguration] = useState('');
  const [configurationWarning, setConfigurationWarning] = useState('');
*/


useEffect(() => {
  // Fetch configurations from the backend
  const fetchConfigurations = async () => {
    try {
      const response = await fetch(`${backendUrl}company/configurations?department_id=${selectedDepartment.department_id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setConfigurations(data);
        // setFilteredConfigurations(data);  // For multiple configurations per department
      } else {
        // Token might be invalid or not admin
        setToken(null);
        navigate('/login');
      }
    } catch (error) {
      console.error('Error fetching configurations:', error);
    }
  };

  if (selectedDepartment) {
    fetchConfigurations();
  }
}, [selectedDepartment, token, setToken, navigate]);


/*  Code for multiple configurations per department
// Filter configurations as configurationSearchTerm changes
useEffect(() => { // for single configuration per department
  const term = configurationSearchTerm;
  const { sanitized } = sanitizeInput(term);
 const filteredConfiguration = configurations.filter(d => d.configuration_id === sanitized);
  setFilteredConfigurations(filteredConfiguration);
}, [configurationSearchTerm, configurations]);

useEffect(() => { 
  const term = configurationSearchTerm;
  const { sanitized } = sanitizeInput(term);
  const filteredConfiguration = configurations.filter(d => d.configuration_id === sanitized);
  setFilteredConfigurations(filteredConfiguration);
}, [configurationSearchTerm, configurations]);

const handleConfigurationSearchChange = (e) => {
  setConfigurationSearchTerm(e.target.value);
};
*/


const handleConfigurationClick = (configuration) => {
    if (selectedConfiguration && selectedConfiguration.configuration_id === configuration.configuration_id) {
        // Deselect
        setSelectedConfiguration(null);
      } else {
        // Select this configuration
        setSelectedConfiguration(configuration);
      }
    setSelectedConfigurationChildTables(null);
    };


/*  code for modifying configurations in the database
const handleAddConfigurationClick = () => {
  const { sanitized } = sanitizeInput(configurationSearchTerm);
  if (sanitized.trim() !== '') {
    setConfiguration(sanitized);
  } else {
    setConfiguration('');
  }
  setConfigurationWarning('');
  setShowAddConfigurationDialog(true);
};

const handleAddConfiguration = async () => {
  if (!Configuration) {
    alert('Please fill in configuration field');
    return;
  }

  try {
    const response = await fetch(`${backendUrl}company/add-configuration`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      });

      if (response.ok) {
          const Configuration = await response.json();
          const updatedConfigurations = [...configurations, Configuration];
          setConfigurations(updatedConfigurations);
          const { sanitized } = sanitizeInput(configurationSearchTerm.toLowerCase());
          setFilteredConfigurations(updatedConfigurations.filter(d => d.name.toLowerCase().includes(sanitized)));
          alert(Configuration + ' successfully added');
          setShowAddConfigurationDialog(false);
          setConfiguration('');
          setSelectedConfiguration(Configuration);
        } else {
          const errData = await response.json();
          alert(`Failed to add configuration: ${errData.detail || 'Unknown error'}`);
        }
      } catch (error) {
        console.error('Error adding configuration:', error);
      }
    };

    // >>> Edit Configuration <<<
  const handleEditConfigurationClick = () => {
    if (!selectedConfiguration) {
      alert('No configuration selected to edit');
      return;
    }
    // Pre-fill with current configuration info
    setConfiguration(selectedConfiguration.name);
    setShowEditConfigurationDialog(true);
  };

  const handleEditConfiguration = async () => {
    if (!selectedConfiguration) return;
    // sanitize again
    const { sanitized: configurationSanitized, unsanatizedFound: configurationInvalid } = sanitizeInput(Configuration);

    if (configurationInvalid) {
      setConfigurationWarning("You may only use alphanumeric and special characters");
      return;
    }

    try {
      const response = await fetch(`${backendUrl}company/update-configuration?configuration_id=${selectedConfiguration.configuration_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          configuration_id: configurationSanitized,
        }),
      });

      if (response.ok) {
        const updatedConfiguration = await response.json();
        // Update local configuration array
        let updatedConfigurations = configurations.filter(d => d.configuration_id !== selectedConfiguration.configuration_id);
        updatedConfigurations = [...updatedConfigurations, updatedConfiguration];
        setConfigurations(updatedConfigurations);
        const { sanitized } = sanitizeInput(configurationSearchTerm.toLowerCase());
        setFilteredConfigurations(updatedConfigurations.filter(d => d.name.toLowerCase().includes(sanitized)));
        alert(configurationSanitized + ' successfully updated');
        setShowEditConfigurationDialog(false);
        setConfiguration('');
        setSelectedConfiguration(updatedConfiguration);
      } else {
        const errData = await response.json();
        alert(`Failed to edit configuration: ${errData.detail || 'Unknown error'}`);
      }
    } catch (error) {
      console.error('Error editing configuration:', error);
    }
  };

const handleDeleteConfigurationClick = () => {
  if (!selectedConfiguration) {
    alert('No configuration selected to delete');
    return;
  }
  setShowDeleteConfigurationDialog(true);
};

const handleDeleteConfiguration = async () => {
  if (!selectedConfiguration) return;
  try {
    const response = await fetch(`${backendUrl}company/delete-configuration?configuration_id=${selectedConfiguration.configuration_id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const updatedConfigurations = configurations.filter(d => d.configuration_id !== selectedConfiguration.configuration_id);
      setConfigurations(updatedConfigurations);
      const { sanitized } = sanitizeInput(configurationSearchTerm.toLowerCase());
      setFilteredConfigurations(updatedConfigurations.filter(d => d.name.toLowerCase().includes(sanitized)));
      alert(selectedConfiguration.name + ' deleted successfully');
      setSelectedConfiguration(null);
      setShowDeleteConfigurationDialog(false);
    } else {
      const errData = await response.json();
        alert(`Failed to delete configuration: ${errData.detail || 'Unknown error'}`);
    }
  } catch (error) {
    console.error('Error deleting configuration:', error);
  }
};

  // On-change handlers for the dialog inputs with inline sanitization
  const handleConfigurationChange = (e) => {
      const { sanitized, unsanatizedFound } = sanitizeInput(e.target.value);
      setConfiguration(sanitized);
      setConfigurationWarning(unsanatizedFound ? "You may only use alphanumeric and the following special characters: .,-@!#$%&*()" : "");
    };
*/







  // Modify configuration child tables
  const [windowsConfiguration, setWindowsConfiguration] = useState([]);
  const [chocolateyAppList, setChocolateyAppList] = useState([]);
  const [executable, setExecutable] = useState([]);
  const [selectedConfigurationChildTables, setSelectedConfigurationChildTables] = useState(null);

/* Code for displaying configuration child tables edit options
  const [filteredConfigurationChildTables, setFilteredConfigurationChildTables] = useState([]);
  const [configurationChildTablesSearchTerm, setConfigurationChildTablesSearchTerm] = useState('');
  const [showAddConfigurationChildTablesDialog, setShowAddConfigurationChildTablesDialog] = useState(false);
  const [showEditConfigurationChildTablesDialog, setShowEditConfigurationChildTablesDialog] = useState(false);
  const [showDeleteConfigurationChildTablesDialog, setShowDeleteConfigurationChildTablesDialog] = useState(false);
  const [ConfigurationChildTables, setConfigurationChildTables] = useState('');
  const [configurationChildTablesWarning, setConfigurationChildTablesWarning] = useState('');
*/


useEffect(() => {
  // Fetch configurationChildTables from the backend
  const fetchWindowsConfiguration = async () => {
    try {
      const response = await fetch(`${backendUrl}company/windows-configuration?configuration_id=${selectedConfiguration.configuration_id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setWindowsConfiguration(data);
//        setFilteredConfigurationChildTables(data);  // For multiple configuration child tables per department
      } else {
        // Token might be invalid or not admin
        setToken(null);
        navigate('/login');
      }
    } catch (error) {
      console.error('Error fetching Windows configuration:', error);
    }
  };


    const fetchChocolateyAppList = async () => {
      try {
        const response = await fetch(`${backendUrl}company/chocolatey-app-list?configuration_id=${selectedConfiguration.configuration_id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setChocolateyAppList(data);
  //        setFilteredConfigurationChildTables(data);  // For multiple configurations per department
        } else {
          // Token might be invalid or not admin
          setToken(null);
          navigate('/login');
        }
      } catch (error) {
        console.error('Error fetching Chocolatey App List:', error);
      }
    };

  const fetchExecutable = async () => {
    try {
      const response = await fetch(`${backendUrl}company/executable?configuration_id=${selectedConfiguration.configuration_id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setExecutable(data);
//        setFilteredConfigurationChildTables(data);  // For multiple configurations per department
      } else {
        // Token might be invalid or not admin
        setToken(null);
        navigate('/login');
      }
    } catch (error) {
      console.error('Error fetching executable:', error);
    }
  };

  if (selectedConfiguration) {
    fetchWindowsConfiguration();
    fetchChocolateyAppList();
    fetchExecutable();
  }
}, [selectedConfiguration, token, setToken, navigate]);


/*  Code for multiple configurations per department
// Filter configurations as configurationSearchTerm changes
useEffect(() => { // for single configuration per department
  const term = configurationSearchTerm;
  const { sanitized } = sanitizeInput(term);
 const filteredConfigurationChildTables = configurations.filter(d => d.configuration_id === sanitized);
  setFilteredConfigurationChildTables(filteredConfigurationChildTables);
}, [configurationSearchTerm, configurations]);

useEffect(() => { 
  const term = configurationSearchTerm;
  const { sanitized } = sanitizeInput(term);
  const filteredConfigurationChildTables = configurations.filter(d => d.configuration_id === sanitized);
  setFilteredConfigurationChildTables(filteredConfigurationChildTables);
}, [configurationSearchTerm, configurations]);

const handleConfigurationChildTablesSearchChange = (e) => {
  setConfigurationChildTablesSearchTerm(e.target.value);
};
*/


const handleConfigurationChildTablesClick = (configurationChildTables) => {
    if (selectedConfigurationChildTables && selectedConfigurationChildTables.configuration_id === configurationChildTables.configuration_id) {
        // Deselect
        setSelectedConfigurationChildTables(null);
      } else {
        // Select this configuration child table
        setSelectedConfigurationChildTables(selectedConfigurationChildTables);
      }};


/*  code for modifying configuration child tables in the database
const handleAddConfigurationChildTablesClick = () => {
  const { sanitized } = sanitizeInput(configurationChildTablesSearchTerm);
  if (sanitized.trim() !== '') {
    setConfigurationChildTables(sanitized);
  } else {
    setConfigurationChildTables('');
  }
  setConfigurationChildTablesWarning('');
  setShowAddConfigurationChildTablesDialog(true);
};

const handleAddConfigurationChildTables = async () => {
  if (!ConfigurationChildTables) {
    alert('Please fill in configuration child tables field');
    return;
  }

  try {
    const response = await fetch(`${backendUrl}company/add-configuration`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      });

      if (response.ok) {
          const ConfigurationChildTables = await response.json();
          const updatedConfigurationChildTables = [...configurations, ConfigurationChildTables];
          setConfigurationChildTables(updatedConfigurationChildTables);
          const { sanitized } = sanitizeInput(configurationSearchTerm.toLowerCase());
          setFilteredConfigurationChildTables(updatedConfigurationChildTables.filter(d => d.name.toLowerCase().includes(sanitized)));
          alert(ConfigurationChildTables + ' successfully added');
          setShowAddConfigurationChildTablesDialog(false);
          setConfigurationChildTables('');
          setSelectedConfigurationChildTables(ConfigurationChildTables);
        } else {
          const errData = await response.json();
          alert(`Failed to add configuration: ${errData.detail || 'Unknown error'}`);
        }
      } catch (error) {
        console.error('Error adding configuration:', error);
      }
    };

    // >>> Edit ConfigurationChildTables <<<
  const handleEditConfigurationChildTablesClick = () => {
    if (!selectedConfigurationChildTables) {
      alert('No configuration selected to edit');
      return;
    }
    // Pre-fill with current configuration info
    setConfigurationChildTables(selectedConfigurationChildTables.name);
    setShowEditConfigurationChildTablesDialog(true);
  };

  const handleEditConfigurationChildTables = async () => {
    if (!selectedConfigurationChildTables) return;
    // sanitize again
    const { sanitized: configurationSanitized, unsanatizedFound: configurationInvalid } = sanitizeInput(ConfigurationChildTables);

    if (configurationInvalid) {
      setConfigurationChildTablesWarning("You may only use alphanumeric and special characters");
      return;
    }

    try {
      const response = await fetch(`${backendUrl}company/update-configuration?configuration_id=${selectedConfiguration.configuration_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          configuration_id: configurationSanitized,
        }),
      });

      if (response.ok) {
        const updatedConfigurationChildTables = await response.json();
        // Update local configuration array
        let updatedConfigurationChildTables = configurations.filter(d => d.configuration_id !== selectedConfigurationChildTables.configuration_id);
        updatedConfigurationChildTables = [...updatedConfigurationChildTables, updatedConfigurationChildTables];
        setConfigurationChildTables(updatedConfigurationChildTables);
        const { sanitized } = sanitizeInput(configurationSearchTerm.toLowerCase());
        setFilteredConfigurationChildTables(updatedConfigurationChildTables.filter(d => d.name.toLowerCase().includes(sanitized)));
        alert(configurationSanitized + ' successfully updated');
        setShowEditConfigurationChildTablesDialog(false);
        setConfigurationChildTables('');
        setSelectedConfigurationChildTables(updatedConfigurationChildTables);
      } else {
        const errData = await response.json();
        alert(`Failed to edit configuration: ${errData.detail || 'Unknown error'}`);
      }
    } catch (error) {
      console.error('Error editing configuration:', error);
    }
  };

const handleDeleteConfigurationChildTablesClick = () => {
  if (!selectedConfigurationChildTables) {
    alert('No configuration selected to delete');
    return;
  }
  setShowDeleteConfigurationChildTablesDialog(true);
};

const handleDeleteConfigurationChildTables = async () => {
  if (!selectedConfigurationChildTables) return;
  try {
    const response = await fetch(`${backendUrl}company/delete-configuration?configuration_id=${selectedConfigurationChildTables.configuration_id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const updatedConfigurationChildTables = configurations.filter(d => d.configuration_id !== selectedConfigurationChildTables.configuration_id);
      setConfigurationChildTables(updatedConfigurationChildTables);
      const { sanitized } = sanitizeInput(configurationSearchTerm.toLowerCase());
      setFilteredConfigurationChildTables(updatedConfigurationChildTables.filter(d => d.name.toLowerCase().includes(sanitized)));
      alert(selectedConfigurationChildTables.name + ' deleted successfully');
      setSelectedConfigurationChildTables(null);
      setShowDeleteConfigurationChildTablesDialog(false);
    } else {
      const errData = await response.json();
        alert(`Failed to delete configuration: ${errData.detail || 'Unknown error'}`);
    }
  } catch (error) {
    console.error('Error deleting configuration:', error);
  }
};

  // On-change handlers for the dialog inputs with inline sanitization
  const handleConfigurationChildTablesChange = (e) => {
      const { sanitized, unsanatizedFound } = sanitizeInput(e.target.value);
      setConfigurationChildTables(sanitized);
      setConfigurationChildTablesWarning(unsanatizedFound ? "You may only use alphanumeric and the following special characters: .,-@!#$%&*()" : "");
    };
*/









    return (
    <div>
      <h1>Company Management</h1>
      <label>
        <input
        type="checkbox"
        checked={advancedVisibility.allColumns}
        onChange={() => toggleAdvancedVisibility('allColumns')}
        />
        Show advanced fields
        </label>

      <h2>Companies</h2>
      {/* Company search bar */}
        <input
        type="text"
        placeholder="Search companies..."
        value={companySearchTerm}
        onChange={handleCompanySearchChange}
      />

      {/* Add company button */}
      <button onClick={handleAddCompanyClick}>Add Company</button>

      {/* Edit company button */}
      <button onClick={handleEditCompanyClick} disabled={!selectedCompany}>Edit</button>

      {/* Delete company button */}
      <button onClick={handleDeleteCompanyClick} disabled={!selectedCompany}>Delete Company</button>

      {/* Company List */}
      <ul style={{listStyle: 'none', padding:0}}>
        <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '10px' }}>
        <thead>
          <tr>
            <th
              style={tableHeaderStyle}
              //onClick={() => handleSort('email')}
            >
              Name
            </th>
            {advancedVisibility.allColumns && 
            <th
              style={tableHeaderStyle}
              //onClick={() => handleSort('email')}
            >
              Company ID
            </th>
            }
            {advancedVisibility.allColumns && <th style={tableHeaderStyle}>Subdomain</th>}
            {advancedVisibility.allColumns && <th style={tableHeaderStyle}>Branding info</th>}
          </tr>
        </thead>
        <tbody>
          {filteredCompany.map(c => {
            const isSelected = selectedCompany && selectedCompany.company_id === c.company_id;
            return (
              <tr
                key={c.company_id}
                onClick={() => handleCompanyClick(c)}
                style={{
                  backgroundColor: isSelected ? '#ddd' : 'transparent',
                  cursor: 'pointer'
                }}
              >
                <td style={tableCellStyle}>{c.name}</td>
                {advancedVisibility.allColumns && <td style={tableCellStyle}>{c.company_id}</td>}
                {advancedVisibility.allColumns && <td style={tableCellStyle}>{c.subdomain || ''}</td>}
                {advancedVisibility.allColumns && <td style={tableCellStyle}>{c.branding_info || ''}</td>}
              </tr>
            );
          })}
        </tbody>
      </table>
      </ul>

      {/* Add Company Dialog */}
      {showAddCompanyDialog && (
        <div style={dialogStyle}>
          <h3>Add  Company</h3>
          <label>Company Name:
            <input type="text" value={CompanyName} onChange={handleCompanyNameChange} />
            {companyNameWarning && <div style={{color: 'red'}}>{companyNameWarning}</div>}
          </label><br/>
          <label>Subdomain:
            <input type="text" value={Subdomain} onChange={handleSubdomainChange} />
            {subdomainWarning && <div style={{color: 'red'}}>{subdomainWarning}</div>}
          </label><br/>
          <label>Branding Info:
            <input type="text" value={BrandingInfo} onChange={handleBrandingInfoChange} />
            {brandingInfoWarning && <div style={{color: 'red'}}>{brandingInfoWarning}</div>}
          </label><br/>
          <label>
            Active subscription
            <input
            type="checkbox"
            checked={activeSubscription=== true}
            onChange={() => setActiveSubscription(!activeSubscription)}
            />
            </label><br/>
          <button onClick={() => setShowAddCompanyDialog(false)}>Cancel</button>
          <button onClick={handleAddCompany}>Add Company</button>
        </div>
      )}

      {/* Edit Company Dialog */}
      {showEditCompanyDialog && selectedCompany && (
        <div style={dialogStyle}>
          <h3>Edit Company</h3>
          <label>
            Company Name:
            <input
              type="text"
              value={CompanyName}
              onChange={(n) => {
                handleCompanyNameChange(n)
              }
            }
            />
            {companyNameWarning && <div style={{color: 'red'}}>{companyNameWarning}</div>}
          </label><br/>
          <label>
            Subdomain:
            <input
              type="text"
              value={Subdomain}
              onChange={(s) => {
                handleSubdomainChange(s)
              }
            }
            />
            {subdomainWarning && <div style={{color: 'red'}}>{subdomainWarning}</div>}
          </label><br/>
          <label>
            Branding info:
            <input
              type="text"
              value={BrandingInfo}
              onChange={(b) => {
                handleBrandingInfoChange(b)
              }
            }
            />
            {brandingInfoWarning && <div style={{color: 'red'}}>{brandingInfoWarning}</div>}
          </label><br/>
          <label>
            Active subscription
            <input
            type="checkbox"
            checked={activeSubscription === true}
            onChange={() => setActiveSubscription(!activeSubscription)}
            />
            </label><br/>
          <button onClick={() => setShowEditCompanyDialog(false)}>Cancel</button>
          <button onClick={handleEditCompany} disabled={companyNameWarning !== ''}>Save</button>
        </div>
      )}

      {/* Delete Company Confirmation */}
      {showDeleteCompanyDialog && selectedCompany && (
        <div style={dialogStyle}>
          <p>Are you sure you want to delete {selectedCompany.name}? This action cannot be undone.</p>
          <button onClick={() => setShowDeleteCompanyDialog(false)}>Back</button>
          <button onClick={handleDeleteCompany}>Delete {selectedCompany.name}</button>
        </div>
      )}

      {selectedCompany && (
        <>
        <h2>Users</h2>
      {/* User search bar */}
      <input
        type="text"
        placeholder="Search users..."
        value={userSearchTerm}
        onChange={handleUserSearchChange}
      />

      {/* Add user button */}
      <button onClick={handleAddUserClick}>Add User</button>

      {/* Edit user button */}
      <button onClick={handleEditUserClick} disabled={!selectedUser}>Edit</button>

      {/* Delete user button */}
      <button onClick={handleDeleteUserClick} disabled={!selectedUser}>Delete User</button>

      {/* User List */}
      <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '10px' }}>
        <thead>
          <tr>
            <th
              style={tableHeaderStyle}
              onClick={() => handleSort('email')}
            >
              Email
            </th>
            <th
              style={tableHeaderStyle}
              onClick={() => handleSort('role')}
            >
              Role
            </th>
            {advancedVisibility.allColumns && <th style={tableHeaderStyle}>User ID</th>}
            {advancedVisibility.allColumns && <th style={tableHeaderStyle}>Company ID</th>}
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map(u => {
            const isSelected = selectedUser && selectedUser.user_id === u.user_id;
            return (
              <tr
                key={u.user_id}
                onClick={() => handleUserRowClick(u)}
                style={{
                  backgroundColor: isSelected ? '#ddd' : 'transparent',
                  cursor: 'pointer'
                }}
              >
                <td style={tableCellStyle}>{u.email}</td>
                <td style={tableCellStyle}>{u.role}</td>
                {advancedVisibility.allColumns && <td style={tableCellStyle}>{u.user_id}</td>}
                {advancedVisibility.allColumns && <td style={tableCellStyle}>{u.company_id}</td>}
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Add User Dialog */}
      {showAddUserDialog && (
        <div style={dialogStyle}>
          <h3>Add  User</h3>
          <label>
            Email:
            <input
              type="text"
              value={Email}
              onChange={(e) => {
                handleEmailChange(e)
              }
            }
            />
            {emailWarning && <div style={{color: 'red'}}>{emailWarning}</div>}
          </label><br/>
          <label>
            Password:
            <input
              type="password"
              value={Password}
              onChange={(e) => {
                handlePasswordChange(e)
              }}
            />
            <ul>
                <li style={{ color: Password.length >= 8 ? 'green' : 'red' }}>
                    Min 8 chars
                    </li>
                    <li style={{ color: /[A-Z]/.test(Password) ? 'green' : 'red' }}>
                    At least 1 capital letter
                    </li>
                    <li style={{ color: /[a-z]/.test(Password) ? 'green' : 'red' }}>
                    At least 1 lowercase letter
                    </li>
                    <li style={{ color: /\d/.test(Password) ? 'green' : 'red' }}>
                    At least 1 digit
                    </li>
                    <li style={{ color: /[!@#$%^&*]/.test(Password) ? 'green' : 'red' }}>
                    At least 1 special character
                    </li>
                    </ul>
            {passwordWarning && <div style={{color: 'red'}}>{passwordWarning}</div>}
          </label><br/>
          <label>
            Role:
            <select
            value={Role}
            onChange={(e) => {
              handleRoleChange(e)
              }}
              >
  <option value="">Select role</option>
  <option value="Administrator">Administrator</option>
  <option value="User">User</option>
</select>
            {roleWarning && <div style={{color: 'red'}}>{roleWarning}</div>}
          </label><br/>
          <button onClick={() => setShowAddUserDialog(false)}>Cancel</button>
          <button onClick={handleAddUser} disabled={emailWarning !== ''}>Add User</button>
        </div>
      )}

      {/* Edit User Dialog */}
      {showEditUserDialog && selectedUser && (
        <div style={dialogStyle}>
          <h3>Edit User</h3>
          <label>
            Email:
            <input
              type="text"
              value={Email}
              onChange={(e) => {
                handleEmailChange(e)
              }
            }
            />
            {emailWarning && <div style={{color: 'red'}}>{emailWarning}</div>}
          </label><br/>
          <label>
            Password:
            {!showPasswordField ? (
            <button onClick={() => setShowPasswordField(true)}>Change Password</button>):(
            <input
              type="password"
              value={Password}
              onChange={(e) => {
                handlePasswordChange(e)
              }
            }
            />)}
            {Password.length===0 ? <></> 
            : <ul>
                <li style={{ color: Password.length >= 8 ? 'green' : 'red' }}>
                Min 8 chars
                </li>
                <li style={{ color: /[A-Z]/.test(Password) ? 'green' : 'red' }}>
                At least 1 capital letter
                </li>
                <li style={{ color: /[a-z]/.test(Password) ? 'green' : 'red' }}>
                At least 1 lowercase letter
                </li>
                <li style={{ color: /\d/.test(Password) ? 'green' : 'red' }}>
                At least 1 digit
                </li>
                <li style={{ color: /[!@#$%^&*]/.test(Password) ? 'green' : 'red' }}>
                At least 1 special character
                </li>
                </ul>
            }
            {passwordWarning && <div style={{color: 'red'}}>{passwordWarning}</div>}
          </label><br/>
          {/* If the user is editing themselves, you might disable role changing */}
          <label>
            Role:
            <select
            value={Role}
            onChange={(e) => {
              handleRoleChange(e)
              }}
              >
  <option value="">Select role</option>
  <option value="Administrator">Administrator</option>
  <option value="User">User</option>
</select>
            {roleWarning && <div style={{color: 'red'}}>{roleWarning}</div>}
          </label><br/>
          <button onClick={() => setShowEditUserDialog(false)}>Cancel</button>
          <button onClick={handleEditUser} disabled={emailWarning !== ''}>Save</button>
        </div>
      )}


      {/* Delete User Confirmation */}
      {showDeleteUserDialog && selectedUser && (
        <div style={dialogStyle}>
          <p>Are you sure you want to delete {selectedUser.email}? This action cannot be undone.</p>
          <button onClick={() => setshowDeleteUserDialog(false)}>Back</button>
          <button onClick={handleDeleteUser}>Delete {selectedUser.email}</button>
        </div>
      )}

      {/* Cannot Delete Self Dialog */}
      {showCannotDeleteSelfDialog && (
        <div style={dialogStyle}>
          <p>You cannot delete your own account. Please sign in with a different administrator account to remove this user.</p>
          <button onClick={() => setShowCannotDeleteSelfDialog(false)}>OK</button>
        </div>
      )}
      </>)}


      {selectedCompany && (
        <>
        <h2>Departments</h2>
        {/* Department search bar */}
        <input
        type="text"
        placeholder="Search departments..."
        value={departmentSearchTerm}
        onChange={handleDepartmentSearchChange}
      />

      {/* Add department button */}
      <button onClick={handleAddDepartmentClick}>Add Department</button>

      {/* Edit department button */}
      <button onClick={handleEditDepartmentClick} disabled={!selectedDepartment}>Edit</button>

      {/* Delete department button */}
      <button onClick={handleDeleteDepartmentClick} disabled={!selectedDepartment}>Delete Department</button>

      {/* Department List */}
      <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '10px' }}>
        <thead>
          <tr>
            <th
              style={tableHeaderStyle}
              //onClick={() => handleSort('email')}
            >
              Name
            </th>
            {advancedVisibility.allColumns && <th style={tableHeaderStyle}>Department ID</th>}
            {advancedVisibility.allColumns && <th style={tableHeaderStyle}>Company ID</th>}
          </tr>
        </thead>
        <tbody>
          {filteredDepartment.map(d => {
            const isSelected = selectedDepartment && selectedDepartment.department_id === d.department_id;
            return (
              <tr
                key={d.department_id}
                onClick={() => handleDepartmentClick(d)}
                style={{
                  backgroundColor: isSelected ? '#ddd' : 'transparent',
                  cursor: 'pointer'
                }}
              >
                <td style={tableCellStyle}>{d.name}</td>
                {advancedVisibility.allColumns && <td style={tableCellStyle}>{d.department_id}</td>}
                {advancedVisibility.allColumns && <td style={tableCellStyle}>{d.company_id}</td>}
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Add Department Dialog */}
      {showAddDepartmentDialog && (
        <div style={dialogStyle}>
          <h3>Add  Department</h3>
          <label>Department Name:
            <input type="text" value={DepartmentName} onChange={handleDepartmentNameChange} />
            {departmentNameWarning && <div style={{color: 'red'}}>{departmentNameWarning}</div>}
          </label><br/>
          <button onClick={() => setShowAddDepartmentDialog(false)}>Cancel</button>
          <button onClick={handleAddDepartment}>Add Department</button>
        </div>
      )}

      {/* Edit Department Dialog */}
      {showEditDepartmentDialog && selectedDepartment && (
        <div style={dialogStyle}>
          <h3>Edit Department</h3>
          <label>
            Department Name:
            <input
              type="text"
              value={DepartmentName}
              onChange={(e) => {
                handleDepartmentNameChange(e)
              }
            }
            />
            {departmentNameWarning && <div style={{color: 'red'}}>{departmentNameWarning}</div>}
          </label><br/>
          <button onClick={() => setShowEditDepartmentDialog(false)}>Cancel</button>
          <button onClick={handleEditDepartment} disabled={departmentNameWarning !== ''}>Save</button>
        </div>
      )}

      {/* Delete Department Confirmation */}
      {showDeleteDepartmentDialog && selectedDepartment && (
        <div style={dialogStyle}>
          <p>Are you sure you want to delete {selectedDepartment.name}? This action cannot be undone.</p>
          <button onClick={() => setShowDeleteDepartmentDialog(false)}>Back</button>
          <button onClick={handleDeleteDepartment}>Delete {selectedDepartment.name}</button>
        </div>
      )}
        </>
      )}
      
      
      {selectedDepartment && (
        <>
      <h2>Configurations</h2>
      {/* Code for displaying configuration edit options
      {// Configuration search bar }
        <input
        type="text"
        placeholder="Search configurations..."
        value={configurationSearchTerm}
        onChange={handleConfigurationSearchChange}
      />

      {// Add configuration button }
      <button onClick={handleAddConfigurationClick}>Add Configuration</button>

      {// Edit configuration button }
      <button onClick={handleEditConfigurationClick} disabled={!selectedConfiguration}>Edit</button>

      {// Delete configuration button }
      <button onClick={handleDeleteConfigurationClick} disabled={!selectedConfiguration}>Delete Configuration</button>
*/}
      {/* Configuration List */}
      <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '10px' }}>
        <thead>
          <tr>
            <th
              style={tableHeaderStyle}
              //onClick={() => handleSort('email')}
            >
              Configuration ID
            </th>
            {advancedVisibility.allColumns && <th style={tableHeaderStyle}>Department ID</th>}
          </tr>
        </thead>
        <tbody>
          {configurations.map(c => {
            const isSelected = selectedConfiguration && selectedConfiguration.configuration_id === c.configuration_id;
            return (
              <tr
                key={c.configuration_id}
                onClick={() => handleConfigurationClick(c)}
                style={{
                  backgroundColor: isSelected ? '#ddd' : 'transparent',
                  cursor: 'pointer'
                }}
              >
                <td style={tableCellStyle}>{c.configuration_id}</td>
                {advancedVisibility.allColumns && <td style={tableCellStyle}>{c.department_id}</td>}
              </tr>
            );
          })}
        </tbody>
      </table>
      
      {/* Code for displaying configuration edit options
      {// Add Configuration Dialog }
      {showAddConfigurationDialog && (
        <div style={dialogStyle}>
          <h3>Add  Configuration</h3>
          <label>Configuration Name:
            <input type="text" value={Configuration} onChange={handleConfigurationChange} />
            {configurationWarning && <div style={{color: 'red'}}>{configurationWarning}</div>}
          </label><br/>
          <button onClick={() => setShowAddConfigurationDialog(false)}>Cancel</button>
          <button onClick={handleAddConfiguration}>Add Configuration</button>
        </div>
      )}

      {// Edit Configuration Dialog }
      {showEditConfigurationDialog && selectedConfiguration && (
        <div style={dialogStyle}>
          <h3>Edit Configuration</h3>
          <label>
            Configuration Name:
            <input
              type="text"
              value={Configuration}
              onChange={(e) => {
                handleConfigurationChange(e)
              }
            }
            />
            {configurationWarning && <div style={{color: 'red'}}>{configurationWarning}</div>}
          </label><br/>
          <button onClick={() => setShowEditConfigurationDialog(false)}>Cancel</button>
          <button onClick={handleEditConfiguration} disabled={configurationWarning !== ''}>Save</button>
        </div>
      )}

      {// Delete Configuration Confirmation }
      {showDeleteConfigurationDialog && selectedConfiguration && (
        <div style={dialogStyle}>
          <p>Are you sure you want to delete {selectedConfiguration.name}? This action cannot be undone.</p>
          <button onClick={() => setShowDeleteConfigurationDialog(false)}>Back</button>
          <button onClick={handleDeleteConfiguration}>Delete {selectedConfiguration.name}</button>
        </div>
      )}
        */}
        </>
      )}
      
      
      {selectedConfiguration && (
        <>
        <h2>Configuration Child Tables</h2>
      {/* Code for displaying configuration edit options
      {// Configuration search bar }
        <input
        type="text"
        placeholder="Search configurations..."
        value={configurationSearchTerm}
        onChange={handleConfigurationSearchChange}
      />

      {// Add configuration child table button }
      <button onClick={handleAddConfigurationChildTablesClick}>Add Configuration</button>

      {// Edit configuration child table button }
      <button onClick={handleEditConfigurationChildTablesClick} disabled={!selectedConfiguration}>Edit</button>

      {// Delete configuration child table button }
      <button onClick={handleDeleteConfigurationChildTablesClick} disabled={!selectedConfiguration}>Delete Configuration</button>
*/}
      {/* Configuration Child Table List */}
      <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '10px' }}>
        <thead>
          <tr>
            <th style={tableHeaderStyle}>Windows configuration</th>
            <th style={tableHeaderStyle}>Chocolatey app list</th>
            <th style={tableHeaderStyle}>Download link</th>
            <th style={tableHeaderStyle}>Created at</th>
            {advancedVisibility.allColumns && <th style={tableHeaderStyle}>Windows configuration ID</th>}
            {advancedVisibility.allColumns && <th style={tableHeaderStyle}>App list ID</th>}
            {advancedVisibility.allColumns && <th style={tableHeaderStyle}>Executable ID</th>}
            {advancedVisibility.allColumns && <th style={tableHeaderStyle}>Configuration ID</th>}
          </tr>
        </thead>
        <tbody>
          {windowsConfiguration.map((winConfig, index) => {
            const isSelected = selectedConfigurationChildTables && selectedConfigurationChildTables.configuration_id === winConfig.configuration_id;
            return (
              <tr
                key={winConfig.configuration_id}
                onClick={() => handleConfigurationChildTablesClick(winConfig)}
                style={{
                  backgroundColor: isSelected ? '#ddd' : 'transparent',
                  cursor: 'pointer'
                }}
              >
              <td style={tableCellStyle}>{winConfig.settings}</td>
              <td style={tableCellStyle}>{chocolateyAppList[index]?.app_name}</td>
              <td style={tableCellStyle}>{executable[index]?.download_link}</td>
              <td style={tableCellStyle}>{executable[index]?.created_at}</td>
              {advancedVisibility.allColumns && <td style={tableCellStyle}>{winConfig.windows_config_id}</td>}
              {advancedVisibility.allColumns && <td style={tableCellStyle}>{chocolateyAppList[index]?.app_list_id}</td>}
              {advancedVisibility.allColumns && <td style={tableCellStyle}>{executable[index]?.executable_id}</td>}
              {advancedVisibility.allColumns && <td style={tableCellStyle}>{winConfig.configuration_id}</td>}
              </tr>
            );
          })}
        </tbody>
      </table>
      </>
)}
    </div>
  );  
}


export default UniversalAdminCompanyManagement;
