// ProtectedRoute.js

import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { backendUrl } from './Endpoints.js';

// We'll assume you have a way to get user info from the token
// If not, consider making an API call to /users/me after setting token

function ProtectedRoute({ component: Component, token, setToken, adminOnly, universalAdminOnly }) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      if (!token) return;
      try {
        const response = await fetch(`${backendUrl}users/me`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          setUser(data);
        } else {
          setToken(null);
        }
      } catch (error) {
        console.error('Error fetching current user:', error);
        setToken(null);
      }
    };
    fetchUser();
  }, [token, setToken]);

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  // Wait for user to be loaded
  if (user === null) {
    return <div>Loading...</div>;
  }

  // If adminOnly is required, check user.role
  if (adminOnly && !["admin", "administrator"].includes(user.role.toLowerCase())) {
    return <div>You are not authorized to access this page.</div>;
  }

  // If universalAdminOnly is required, check user.company_id
  if (universalAdminOnly && user.company_id !== 1) {
    return <div>You are not authorized to access this page.</div>;
  }

  return <Component token={token} setToken={setToken} />;
}

export default ProtectedRoute;