import React, { useState, useEffect } from 'react';

import { backendUrl } from './Endpoints.js';    // Imports the backend URL
//import { useNavigate } from 'react-router-dom'; // Handles redirecting if token is invalid


const Logs = ({ token, setToken }) => {
//    const navigate = useNavigate();
    const [logs, setLogs] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const today = new Date();   // Get the current date for end date
    const oneMonthAgo = new Date(); // Get the date one month ago for start date
    oneMonthAgo.setMonth(today.getMonth() - 1);

    const [startDate, setStartDate] = useState(oneMonthAgo.toISOString().split('T')[0]);
    const [endDate, setEndDate] = useState(today.toISOString().split('T')[0]);

    useEffect(() => {
        // Gets a list of logs from the backend
        const fetchLogs = async () => {
            try { 
                const response = await fetch(`${backendUrl}logs`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`, 
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch logs');
                }

                const data = await response.json();

                // Sort logs by timestamp
                const sortedLogs = data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
                setLogs(sortedLogs);

            } catch (error) {
                console.error('Error fetching logs:', error);
            }
        };
        fetchLogs();
    }, [token]);

    const filteredLogsBySearch = () => {    // Filters logs by text in search bar
        return filterLogsByDate().filter(log => 
            log.action.toLowerCase().includes(searchTerm.toLowerCase()) ||
            log.user.toLowerCase().includes(searchTerm.toLowerCase()) ||
            log.status.toLowerCase().includes(searchTerm.toLowerCase())
        );
    };
    
    
    const filterLogsByDate = () => {    // Filters logs by date range
        return logs.filter(log => {
            const logDate = new Date(log.timestamp);
            const start = startDate ? new Date(startDate) : null;
            const end = endDate ? new Date(endDate) : null;
            if (end) {
                end.setHours(23, 59, 59, 999); // Set end date to the end of the day
            }
            return (!start || logDate >= start) && (!end || logDate <= end);
        });
    };

    const handleStartDateChange = (e) => {
        const selectedDate = new Date(e.target.value);
        const minDate = new Date('2025-01-01');
        const maxDate = new Date(endDate);
        if (selectedDate >= minDate && selectedDate <= maxDate) {
            selectedDate.setUTCHours(0, 0, 0, 0); // Use setUTCHours to avoid timezone issues
            setStartDate(selectedDate.toISOString().split('T')[0]);
        } else {
            alert('Start date must be between January 1st, 2025 and the end date.');
        }
    };

    const handleEndDateChange = (e) => {
        const selectedDate = new Date(e.target.value);
        const minDate = new Date(startDate);
        const maxDate = new Date();
        if (selectedDate >= minDate && selectedDate <= maxDate) {
            selectedDate.setHours(23, 59, 59, 999);
            setEndDate(selectedDate.toISOString().split('T')[0]);
        } else {
            alert('End date must be between the start date and today.');
        }
    };





     // Export logs to a JSON file (backend queries for and returns one)
     const exportLogs = async () => {   // Requests JSON log file from backend (optional date range)
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };
            
            if (startDate) {
                headers['Start-Date'] = new Date(startDate).toISOString();
            }

            if (endDate) {
                const endDateTime = new Date(endDate);
                endDateTime.setHours(23, 59, 59, 999);
                headers['End-Date'] = endDateTime.toISOString();
            }

            const response = await fetch(`${backendUrl}api/logs`, {
                method: 'GET',
                headers: headers
            });

            if (!response.ok) {
                throw new Error('Failed to export logs');
            }

            // Create a blob from the response
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'QXBuilder-logs.json'; // Set the filename for download
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (error) {
            console.error('Error exporting logs:', error);
        }
    };


    

    return (
        <div>
            <h1>Logs</h1>
            <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
            />
            <label>
                Start Date:
                <input
                    type="date"
                    value={startDate}
                    onChange={handleStartDateChange}
                />
            </label>
            <label>
                End Date:
                <input
                    type="date"
                    value={endDate}
                    onChange={handleEndDateChange}
                />
            </label>
            <button onClick={exportLogs}>Export Logs</button>
            <table>
                <thead>
                    <tr>
                        <th>User</th>
                        <th>Action</th>
                        <th>Time</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredLogsBySearch().map(log => (
                        <tr key={log.id}>
                            <td>{log.user}</td>
                            <td>{log.action}</td>
                            <td>{log.timestamp}</td>
                            <td>{log.status}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Logs;