import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { backendUrl } from './Endpoints.js';    // Imports the backend URL
import { sanitizeInput } from './utils.js'; // Import function to sanatize string inputs in the frontend


const PackageSelector = ({ token, setToken }) => {
    const navigate = useNavigate();


    const [deselectedPackages, setDeselectedPackages] = useState([]);
    const [selectedPackages, setSelectedPackages] = useState([]);
    const [deselectedSearch, setDeselectedSearch] = useState('');
    const [selectedSearch, setSelectedSearch] = useState('');
//    const [chocolateyQuery, setChocolateyQuery] = useState(''); // State for Chocolatey search
//    const [chocolateyResults, setChocolateyResults] = useState([]); // State for search results
//    const [deselectedWarning, setDeselectedWarning] = useState('');   // Warns of invalid input in Deselected search field
//    const [selectedWarning, setSelectedWarning] = useState('');   // Warns of invalid input in Selected search field
    const [sortConfig, setSortConfig] = useState({ key: 'title', direction: 'ascending' }); // Sorting state
    const [departments, setDepartments] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState(null);


        // State for column visibility
        const [columnVisibility, setColumnVisibility] = useState({
            id: false,
            title: true,
            version: false, // Also determines whether version is LATEST or selectable
            summary: true,
            source: false,
        });
    
        const toggleColumnVisibility = (column) => {
            setColumnVisibility(prevState => ({
                ...prevState,
                [column]: !prevState[column],
            }));
        };



        useEffect(() => {
            // Set up Axios interceptors to include the token in every request
            axios.interceptors.request.use(
                config => {
                    const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
                    if (token) {
                        config.headers.Authorization = `Bearer ${token}`;
                    }
                    return config;
                },
                error => {
                    return Promise.reject(error);
                }
            );
        }, []);


        useEffect(() => {
        const fetchPackages = async () => {
            // Fetch package lists from multiple files
            const filePaths = [
                './Repository package lists/All Chocolatey Packages1.xml',
                './Repository package lists/All Chocolatey Packages2.xml', 
            ];
        
            try {
                const responses = await Promise.all(
                    filePaths.map(filePath => axios.get(filePath))
                );
        
                const allPackages = [];
        
                responses.forEach(response => {
                    const parser = new DOMParser();
                    const xmlDoc = parser.parseFromString(response.data, "application/xml");
                    const packages = Array.from(xmlDoc.getElementsByTagName("entry")).map(entry => {
                        const title = entry.getElementsByTagName("title")[0]?.textContent || "Unknown Title";
                        const version = entry.getElementsByTagName("d:Version")[0]?.textContent || "Unknown Version";
                        const summary = entry.getElementsByTagName("summary")[0]?.textContent || "No summary available";
                        const source = 'Chocolatey'; // Adjust this if needed
                        return { title, version, summary, source };
                    });
                    allPackages.push(...packages); // Combine results
                });
        
                setDeselectedPackages(allPackages); // Set the combined packages to state
            } catch (error) {
                console.error("Error fetching packages:", error);
            }
        };
        fetchPackages();
    }, []);



    const togglePackageSelection = (pkg) => {
        if (selectedPackages.includes(pkg)) {
            // If already selected, remove it from selected and add to deselected
            setSelectedPackages(selectedPackages.filter(p => p !== pkg));
            setDeselectedPackages([...deselectedPackages, pkg]);
        } else {
            // If not selected, add to selected and remove from deselected
            const updatedPkg = {
                ...pkg,
                version: columnVisibility.version ? pkg.version : 'LATEST', // Set version based on toggle
            };
            setSelectedPackages([...selectedPackages, updatedPkg]);
            setDeselectedPackages(deselectedPackages.filter(p => p !== pkg));
        }
    };



    useEffect(() => {});
    // Filter packages based (unsanitized)
    const filteredDeselectedPackages = deselectedPackages.filter(pkg => 
        pkg.title.toLowerCase().includes(deselectedSearch.toLowerCase())
    );

    const filteredSelectedPackages = selectedPackages.filter(pkg => 
        pkg.title.toLowerCase().includes(selectedSearch.toLowerCase())
    );

/*
    // Sanitize search inputs
    const { sanitized: sanitizedDeselectedSearch, unsanatizedFound: deselectedUnsanitizedFound } = sanitizeInput(deselectedSearch);
    const { sanitized: sanitizedSelectedSearch, unsanatizedFound: selectedUnsanitizedFound } = sanitizeInput(selectedSearch);


    // Set warnings based on unsanitized input
    useEffect(() => {
        if (deselectedUnsanitizedFound) {
            setDeselectedWarning("You may only use alphanumeric and special characters");
        } else {
            setDeselectedWarning(''); // Clear warning if input is valid
        }
    }, [sanitizedDeselectedSearch]);


    useEffect(() => {
        if (selectedUnsanitizedFound) {
            setSelectedWarning("You may only use alphanumeric and special characters");
        } else {
            setSelectedWarning(''); // Clear warning if input is valid
        }
    }, [sanitizedSelectedSearch]);

    // Filter packages based on sanitized search input
    const filteredDeselectedPackages = deselectedPackages.filter(pkg => 
        pkg.title.toLowerCase().includes(sanitizedDeselectedSearch.toLowerCase())
    );

    const filteredSelectedPackages = selectedPackages.filter(pkg => 
        pkg.title.toLowerCase().includes(sanitizedSelectedSearch.toLowerCase())
    );
*/

    // Function to remove duplicate packages from Deselected list
    const uniquePackages = (deselectedPackages, selectedPackages) => {
        if (!columnVisibility.version) {
            const uniqueTitles = new Set(selectedPackages.map(pkg => pkg.title)); // Get titles from selected packages
            return deselectedPackages.filter(pkg => {
                if (!uniqueTitles.has(pkg.title)) {
                    uniqueTitles.add(pkg.title); // Add to unique titles if not already present
                    return true; // Keep this package
                }
                return false; // Skip duplicates
            });
        }
        return deselectedPackages; // Return all if version column is visible
    };

 //   const uniqueDeselectedPackages = uniquePackages(filteredDeselectedPackages, selectedPackages);



    // Sorting function
    const sortedPackages = (packages) => {
        const { key, direction } = sortConfig;
        return [...packages].sort((a, b) => {
            if (key === 'version') {
                return direction === 'ascending' ? a[key] - b[key] : b[key] - a[key];
            } else {
                return direction === 'ascending'
                    ? a[key].localeCompare(b[key])
                    : b[key].localeCompare(a[key]);
            }
        });
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };





/*    // New function to search Chocolatey repository through backend
    const handleChocolateySearch = async () => {
        try {
            // Fetch the XML file
            const response = await fetch('./Repository package lists/All Chocolatey Packages.xml'); // Update the path to your XML file
            const xmlText = await response.text(); // Get the response text
    
            // Parse the XML response
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(xmlText, "packagelication/xml");
    
            // Extract package information from the XML
            const packages = [];
            const entries = xmlDoc.getElementsByTagName("entry"); // Adjust based on your XML structure
    
            for (let entry of entries) {
                const title = entry.getElementsByTagName("title")[0].textContent; // Adjust based on your XML structure
                packages.push({ title: title });
            }
    
            // Update the state with the list of packages
            setChocolateyResults(packages);
        } catch (error) {
            console.error("Error reading XML file:", error);
        }
    };


            Move to "return"
            <div>
                <h2>Chocolatey Package Search</h2>
                <input 
                    type="text" 
                    placeholder="Search Chocolatey packages..." 
                    value={chocolateyQuery} 
                    onChange={(e) => setChocolateyQuery(e.target.value)} 
                />
                <button onClick={handleChocolateySearch}>Search</button>
                <ul>
                    {chocolateyResults.map(result => (
                        <li key={result.title}>{result.title}</li> // Adjust based on actual response structure
                    ))}
                </ul>
            </div>
*/



/*
        // New function to search Chocolatey repository
        const handleChocolateySearch = async () => {
            try {
                const response = await axios.post(`${backendUrl}search-chocolatey`, {
                    query: chocolateyQuery 
                });
                setChocolateyResults(response.data); // Assuming the response contains the results you need
            } catch (error) {
                console.error("Error fetching Chocolatey packages:", error);
            }
        };
*/

    const handleDownload = async () => {
        // Check if there are selected packages
    if (selectedPackages.length === 0) {
        alert("Please select at least one package to download."); // Alert user if no packages are selected
        return; // Exit the function if no packages are selected
    }

        try {
            const selectedPackagesData = selectedPackages.map(pkg => ({
                name: pkg.title,
                version: pkg.version,
                source: pkg.source,
                summary: pkg.summary
            }));
    
            const response = await axios.post(`${backendUrl}download-chocolatey-packages`, {
                selected_packages: selectedPackagesData,  // Ensure this is correct
            });
    
            // Handle the response to download the file
            const fileUrl = response.data.file_url; // Assuming the backend sends the file URL
            const link = document.createElement('a');
            const fullUrl = `${backendUrl}${fileUrl}`; // Combine base URL with relative path
            link.href = fullUrl;
            link.setAttribute('download', 'Chocolatey packages.ps1'); // Name of the file
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error("Error sending packages to backend:", error);
        }
    };

    
    
    
    const [companies, setCompanies] = useState([]);
    const [filteredCompany, setFilteredCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [companySearchTerm, setCompanySearchTerm] = useState('');

    useEffect(() => {
        // Fetch companies from the backend
        const fetchCompanies = async () => {
          try {
            const response = await fetch(`${backendUrl}universal/companies`, {
              headers: {
                'Authorization': `Bearer ${token}`,
              },
            });
            if (response.ok) {
              const data = await response.json();
              setCompanies(data);
              setFilteredCompanies(data);
            } else {
              // Token might be invalid or not admin
              setToken(null);
              navigate('/login');
            }
          } catch (error) {
            console.error('Error fetching companies:', error);
          }
        };
      
        fetchCompanies();
      }, [token, setToken, navigate]);
      
      // Filter companies as companySearchTerm changes
      useEffect(() => {
        const term = companySearchTerm.toLowerCase();
        const { sanitized } = sanitizeInput(term);
        const filteredCompany = companies.filter(c => c.name.toLowerCase().includes(sanitized));
        setFilteredCompanies(filteredCompany);
      }, [companySearchTerm, companies]);
      
      const handleCompanySearchChange = (e) => {
        setCompanySearchTerm(e.target.value);
      };
      
      const handleCompanyClick = (company) => {
        if (selectedCompany && selectedCompany.company_id === company.company_id) {
          // Deselect
          setSelectedCompany(null);
        } else {
          // Select this company
          setSelectedCompany(company);
        }
        setSelectedDepartment(null);
      };
    
    
/*    // Dialog styling
    const dialogStyle = {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        border: '1px solid #000',
        padding: '20px',
        background: '#fff',
        zIndex: 1000,
      };*/
    
      // Table header styling
      const tableHeaderStyle = {
        textDecoration: 'underline',
        cursor: 'pointer',
        padding: '5px',
        borderBottom: '1px solid #ccc'
      };
      
    
    // Table cell styling
      const tableCellStyle = {
        padding: '5px',
        borderBottom: '1px solid #eee'
      };




    useEffect(() => {
    // Gets list of departments for saving pkg lists
    const fetchDepartments = async () => {
        try {
          const response = await fetch(`${backendUrl}universal/departments?company_id=${selectedCompany.company_id}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          if (response.ok) {
            const data = await response.json();
            setDepartments(data);
          } else {
            // Token might be invalid or not admin
            setToken(null);
          }
        } catch (error) {
          console.error('Error fetching departments:', error);
        }
      };
      if (selectedCompany) {
        fetchDepartments();
      }
    }, [selectedCompany, setToken, token]);

    const handleSave = async () => {    // Saves the selected packages to the selected department
        if (selectedPackages.length === 0) {
            alert("Please select at least one package to save."); // Alert user if no packages are selected
            return; // Exit the function if no packages are selected
        }
        
        if (!selectedDepartment) {
            alert('Please select which department to save the package list to.'); // Alert user if no department is selected
            return;
        }

        const packageList = selectedPackages.map(pkg => ({
            name: pkg.title,  
            source: pkg.source,
            summary: pkg.summary,
            version: pkg.version
        }));


        try {
            await axios.post(`${backendUrl}save-package-list`, {
                selected_packages: packageList,  // Ensure this is correct
                department_id: selectedDepartment,
            });
        } catch (error) {
            console.error("Error sending packages to backend:", error);
            alert("An error occurred while saving the packages. Please try again.");
        }
    };

    const handleEditConfiguration = async () => {
        if (!selectedDepartment) {
            alert('Please select a department to edit.');
            return;
        }

        try {
            const response = await axios.get(`${backendUrl}get-package-list`, {
                params: { configuration_id: selectedDepartment }
            });
            const packageList = response.data.map(pkg => ({
                title: pkg.name,
                source: pkg.source,
                summary: pkg.summary,
                version: pkg.version
            }));
            setSelectedPackages(packageList);
        } catch (error) {
            console.error('Error fetching package list:', error);
        }
    };

    return (
        <div>
            <h1>Package Selector</h1>
            <div>
                <label>
                    <input
                        type="checkbox"
                        checked={columnVisibility.version}
                        onChange={() => toggleColumnVisibility('version')}
                    />
                    Legacy Package Versions
                </label>
                <label>
                    <input
                        type="checkbox"
                        checked={columnVisibility.title}
                        onChange={() => toggleColumnVisibility('title')}
                    />
                    Title
                </label>
                <label>
                    <input
                        type="checkbox"
                        checked={columnVisibility.summary}
                        onChange={() => toggleColumnVisibility('summary')}
                    />
                    Summary
                </label>
                <label>
                    <input
                        type="checkbox"
                        checked={columnVisibility.source}
                        onChange={() => toggleColumnVisibility('source')}
                    />
                    Source
                </label>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ flex: 1, marginRight: '10px' }}>
                    <h2>Repository Packages</h2>
                    <input 
                        type="text" 
                        placeholder="Search packages..." 
                        value={deselectedSearch} 
                        onChange={(e) => setDeselectedSearch(e.target.value)} 
                    />
                    {/*deselectedWarning && <p style={{ color: 'red' }}>{deselectedWarning}</p>*/}
                    <table>
                        <thead>
                            <tr>
                                {columnVisibility.title && <th onClick={() => handleSort('title')}>Title</th>}
                                {columnVisibility.version && <th>Version</th>}
                                {columnVisibility.summary && <th>Summary</th>}
                                {columnVisibility.source && <th onClick={() => handleSort('source')}>Source</th>}
                            </tr>
                        </thead>
                        <tbody>
                        {sortedPackages(uniquePackages(filteredDeselectedPackages, selectedPackages)).map(pkg => (
                            <tr key={pkg.id} onClick={() => togglePackageSelection(pkg)}>
                                {columnVisibility.title && <td>{pkg.title}</td>}
                                {columnVisibility.version && <td>{pkg.version}</td>}
                                {columnVisibility.summary && <td>{pkg.summary || 'N/A'}</td>}
                                {columnVisibility.source && <td>{pkg.source}</td>}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <div style={{ flex: 1 }}>
                    <div>
                        <h2>Companies</h2>
                        {/* Company search bar */}
                        <input
                        type="text"
                        placeholder="Search companies..."
                        value={companySearchTerm}
                        onChange={handleCompanySearchChange}
                        />
                        
                        {/* Company List */}
                        <ul style={{listStyle: 'none', padding:0}}>
                            <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '10px' }}>
                                <thead>
                                    <tr>
                                        <th
                                        style={tableHeaderStyle}
                                        //onClick={() => handleSort('email')}
                                        >
                                            Name
                                        </th>
                                        <th
                                        style={tableHeaderStyle}
                                        //onClick={() => handleSort('email')}
                                        >
                                            Company ID
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredCompany.map(c => {
                                        const isSelected = selectedCompany && selectedCompany.company_id === c.company_id;
                                        return (
                                            <tr
                                                key={c.company_id}
                                                onClick={() => handleCompanyClick(c)}
                                                style={{
                                                    backgroundColor: isSelected ? '#ddd' : 'transparent',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                <td style={tableCellStyle}>{c.name}</td>
                                                <td style={tableCellStyle}>{c.company_id}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </ul>

                        {selectedCompany && (
                        <>
                            <h2>Select Department</h2>
                            <select onChange={(e) => setSelectedDepartment(e.target.value)}>
                                <option value="">Select a department</option>
                                {departments.map((dept) => (
                                    <option key={dept.department_id} value={dept.department_id}>
                                        {dept.name}
                                        </option>
                                    ))}
                            </select>
                            <button onClick={handleEditConfiguration} disabled={!selectedDepartment}>Load</button>
                            <button onClick={handleSave} disabled={!selectedDepartment}>Save</button>
                        </>
                        )}
                    </div>



                    <h2>Selected Packages</h2>
                    <input 
                        type="text" 
                        placeholder="Search packages..." 
                        value={selectedSearch} 
                        onChange={(e) => setSelectedSearch(e.target.value)} 
                    />
                    <button onClick={handleDownload}>Download</button>
                    {/*selectedWarning && <p style={{ color: 'red' }}>{selectedWarning}</p>*/}
                    <table>
                        <thead>
                            <tr>
                                {columnVisibility.title && <th onClick={() => handleSort('title')}>Title</th>}
                                {columnVisibility.version && <th>Version</th>}
                                {columnVisibility.summary && <th>Summary</th>}
                                {columnVisibility.source && <th onClick={() => handleSort('source')}>Source</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {sortedPackages(filteredSelectedPackages).map(pkg => (
                                <tr key={pkg.id} onClick={() => togglePackageSelection(pkg)}>
                                    {columnVisibility.title && <td>{pkg.title}</td>}
                                    {columnVisibility.version && <td>{pkg.version}</td>}
                                    {columnVisibility.summary && <td>{pkg.summary || 'N/A'}</td>}
                                    {columnVisibility.source && <td>{pkg.source}</td>}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                                                        
                </div>
            </div>
        </div>
    );
};

export default PackageSelector;