// exports to Login.js, CompanyManagement.js
// Function for inline sanitization of strings (only alphanumeric and special characters)
export function sanitizeInput(str) {
    // The same sanitization logic you used in CompanyManagement
    const allowedRegex = /[^\w\s.\-@!#$%&*()]/g;
    const sanitized = str.replace(allowedRegex, '');
    const unsanatizedFound = allowedRegex.test(str);
    return { sanitized, unsanatizedFound };
  }
  

// exports to Login.js, CompanyManagement.js
// Function that uses a simple regex to determine if the email address in the text field is valid
export  function isValidEmail(email) {
    // Simple regex approach
    const simpleEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return simpleEmailRegex.test(email);
  }